.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 12px;
  width: 100%;
  max-width: 115px;
  border: 1px solid var(--border-color);
  border-radius: 12px;
}

.icon {
  width: 20px;
  height: 20px;
  color: var(--background-primary);
}

.count {
  user-select: none;
}

@media (width <= 480px) {
  .count {
    font-size: var(--font-size-p-s);
  }
}