
.icon {
  margin-right: 7px;
  width: 21.5px;
  height: 21.5px;
}

.button {
  justify-content: start;
  padding-left: 10px;
  height: 38px;
  border-radius: 12px;
  color: var(--color-black-1000);
  font-weight: var(--font-weight-500);
  font-size: var(--font-size-p-m);
}

.button:hover {
  background-color: var(--color-black-30);
}
