.toaster {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 17px;
  padding: 7px 13px;
  width: 348px;
  border-radius: 14px;
  background: var(--color-white-900);
  color: var(--color-black-900);
}

.toaster-text-wrapper {
  display: flex;
  flex-direction: column;
}

.error {
  border-bottom: 2px solid var(--color-red-700);
}

.success {
  border-bottom: 2px solid var(--color-green-900);
}

.warning {
  border-bottom: 2px solid var(--color-yellow-900);
}

.title {
  line-height: 21.86px;
  font-weight: var(--font-weight-600);
  font-size: var(--font-size-p-m);
}

.title.error-title {
  color: var(--color-red-700);
}

.title.success-title {
  color: var(--color-green-900);
}

.title.warning-title {
  color: var(--color-yellow-900);
}


.close-btn {
  position: absolute;
  top: -20px;
  right: 0;
  z-index: 999;
  width: 10px;
  height: 10px;
  border: none;
  background: transparent;
  color: var(--color-black-800);
  cursor: pointer;
}

.fade-in {
  opacity: 0;
  animation: fade-in 1s ease forwards;
}

.fade-out {
  opacity: 1;
  animation: fade-out 1s ease forwards;
}

@keyframes fade-in {
  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}