.doc-item {
  gap: 8px;
}

.doc-item-name {
  max-width: 392px;
  color: var(--text-color-primary);
  line-height: 19px;
  font-weight: var(--font-weight-400);
  font-size: var(--font-size-p-m);
  font-family: var(--font-family);   
}

@media (width <= 469px) {
  .doc-item {
    flex-direction: column;
    gap: 0;
  }

  .doc-item-name {
    margin-bottom: 16px;
  }

  .doc-link {
    justify-content: end;
    align-self: flex-end;
  }
}