.content {
  padding: 0;
  padding-top: 40px;
  border-radius: 20px;
  color: var(--color-black-900);
}

.title,
.description {
  padding: 0 40px;
}

.description {
  margin-top: 19px;
}

.buttons-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  margin-top: 30px;
  padding: 30px 40px;
  border-radius: inherit;
  background: var(--color-black-25);
}