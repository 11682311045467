.wrapper {
  margin: 40px 0 16px;
}

@media (width <= 768px) {
  .wrapper {
    margin-top: 30px;
  }
}

@media (width <= 368px) {
  .wrapper {
    margin-top: 40px;
    margin-bottom: 0;
  }
}