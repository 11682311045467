.interview {
  width: calc(100% - 370px - 20px);
}

.statistics {
  width: 370px;
  min-height: 480px;
}

.questions {
  align-self: start;
  width: calc(50% - 10px);
}

.history {
  align-self: start;
  width: calc(50% - 10px);
}

@media (width < 1440px) {
  .interview {
    width: 100%;
  }

  .statistics {
    width: 100%;
    min-height: auto;
  }
}

@media (width < 1280px) {
  .questions {
    width: 100%;
  }

  .history {
    width: 100%;
  }
}

@media (width < 480px) {
  .interview {
    gap: 20px;
  }

  .questions {
    gap: 16px;
  }

  .history {
    gap: 16px;
  }
}