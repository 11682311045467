.wrapper {
  gap: 28px;
}

.input {
  flex: 1;
}

.text-area {
  width: 100%;
  height: 180px;
}

.input-form {
  width: 100%;
}

.text-wrapper {
  width: 100%;
  max-width: 246px;
}

.specialization-input {
  align-items: center;
  height: 48px;
}

.input-form input::placeholder {
  color: var(--text-color-more-lightest);
  font-weight: var(--font-weight-400);
}

.input-form textarea::placeholder {
  color: var(--text-color-more-lightest);
  font-weight: var(--font-weight-500);
}