.description {
  flex: 1 0 350px;
}
  
.description h3 {
  margin-bottom: 8px;
  color: var(--color-black-800);
  font-weight: var(--font-weight-600);
  font-size: var(--font-size-p-l);
}
  
.description p {
  margin-bottom: 8px;
  color: var(--color-black-800);
  font-weight: var(--font-weight-400);
  font-size: var(--font-size-p-s)
}

.inputs-wrapper {
  flex-wrap: wrap;
  max-width: 660px;
}

.label {
  width: 100%;
  max-width: 320px;
}

.label > span {
  display: inline-block;
  margin-bottom: 8px;
  color: var(--color-black-700);
  font-weight: var(--font-weight-400);
  font-size: var(--font-size-p-s);
}

.input {
  display: flex;
  width: auto;
  max-width: 328px;
  min-height: 48px;
}

.input input {
  color: var(--color-black-900);
  font-weight: var(--font-weight-400);
  font-size: var(--font-size-p-m);
}

.form {
  width: 100%;
  max-width: 320px;
}

.form > span {
  display: inline-block;
  margin-bottom: 8px;
  color: var(--color-black-700);
  font-weight: var(--font-weight-400);
  font-size: var(--font-size-p-s);
}

@media (width <= 1440px) {
  .column {
    flex-direction: column;
  }

  .description {
    flex-basis: auto;
  }
}

@media (width <= 768px) {
  .wrapper {
    gap: 24px;
  }

  .form-control-wrapper {
    flex-wrap: wrap;
  }
}