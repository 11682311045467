.param {
  padding: 4px 6px;
  border-radius: 4px;
  background-color: var(--background-light-hover);
}

.value {
  padding: 2px 4px;
  border-radius: 4px;
  background-color: var(--text-color-link-active);
}
