.container {
  height: 100%;
}

.title {
  margin-bottom: 24px;
  line-height: 120%;
  font-weight: var(--font-weight-500); 
}
 
.wrapper {
  margin-bottom: 48px;
}

.additional-wrapper {
  flex-basis: 40%;
}

.button {
  display: flex;
  margin-top: auto;
  margin-left: auto;
  border-radius: 12px;
}

@media (width <=768px) {
  .wrapper {
    display: flex;
    flex-direction: column;
  }
}

@media (width <=480px) {
  .title {
    margin-bottom: 16px;
    font-weight: var(--font-weight-500);
    font-size: var(--font-size-h-xs);
  }

  .additional-wrapper {
    row-gap: 16px;
  }

  .wrapper {
    margin-bottom: 24px;
  }
}