.picture-wrapper {
  width: 100%;
  max-width: 660px;
}

.picture-block {
  position: relative;
  width: 170px;
}

.img {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  object-fit: cover;
}

.file-loader-wrapper {
  flex-grow: 1;
}

.file-loader {
  padding: 14px 0;
  min-height: 114px;
  max-height: 114px;
  line-height: 120%;
  font-size: var(--font-size-p-s);
}

.file-loader > p:nth-child(3) {
  line-height: 120%;
  font-size: var(--font-size-p-xs);
}

.delete-btn {
  position: absolute;
  right: 50%;
  bottom: -28px;
  width: max-content;
  max-width: 170px;
  border: none;
  background-color: transparent;
  color: var(--color-red-600);
  font-weight: var(--font-weight-400);
  font-size: var(--font-size-s);
  transform: translateX(50%);
  cursor: pointer;
}

.loader {
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
}

@media (width <= 768px) {
  .picture-wrapper {
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media (width <= 480px) {
  .picture-block {
    width: 100%;
  }

  .delete-btn {
    align-self: center;
  }
}