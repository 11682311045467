.chip svg{
  width: 20px;
  height: 20px;
}

.chip {
  span {
    padding-left: 10px;
    line-height: 120%;
    font-weight: var(--font-weight-500);
    font-size: var(--font-size-p-m);
  }
}

@media (width <= 480px) {
  .chip {
    font-size: var(--font-size-p-s);
  }
}


