.input {
  width: 100%;
  height: 44px;
}

.yeahub-input-preffix {
  margin: 0;
}

.button {
  pointer-events: none;
}