.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--z-index-modal);
  display: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: var(--background-modal-overlay);

  .modal {
    position: relative;
    margin: 0 20px;
    padding: 40px;
    min-width: 300px;
    max-width: 800px;
    border-radius: 24px;
    background-color: var(--background-block);

    .x-circle {
      position: absolute;
      top: -40px;
      right: -5px;
      margin: 0;
      cursor: pointer;
    }

    .title {
      margin-bottom: 30px;
      padding-bottom: 15px;
      border-bottom: 1px solid var(--border-color);
      font-weight: var(--font-weight-500);
      font-size: var(--font-size-h-s);
    }

    .buttons {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 15px;
      margin-top: 30px;
    }
  }
}

.modal-open {
  display: flex;
}
