.card {
  padding: 80px;
  border-radius: 0;
  background-color: var(--background-light-hover);
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}

.title {
  margin: 0;
  color: var(--text-color-black-landing);
  line-height: 75px;
  text-transform: uppercase;
  font-weight: var(--font-weight-500);
  font-size: var(--font-size-h-xxl);
}

.text {
  margin: 0;
  color: var(--text-color-light);
  font-weight: var(--font-weight-400);
  font-size: var(--font-size-h-xs);
}
	
.error-msg {
  margin: 0;
  color: var(--text-color-light);
  font-weight: var(--font-weight-400);
  font-size: var(--font-size-p-s);
}