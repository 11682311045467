.input {
  flex: 1;
}

.select {
  width: 360px;
}

.status-select select {
  height: 46px;
}

.status-select > div > div {
  border-radius: 12px;
}

.rate {
  position: relative;
  top: -15px;
  width: 360px;
}

.titles {
  width: 135px;
}

.label {
  color: var(--text-color-the-lightest);
  font-weight: var(--font-weight-700);
  font-size: var(--font-size-xsmall);
  font-style: normal;
} 

.description {
  display: block;
  width: 100%;
  height: 200px;
  resize: vertical;
  border-radius: 16px;
}

.title {
  display: block;
  width: 100%;
  height: 180px;
  resize: vertical;
  border-radius: 16px;
}