:root {
  /* сolor palette */
  --color-purple-900: #400799;
  --color-purple-800: #5509CC;
  --color-purple-700: #6A0BFF;
  --color-purple-600: #883CFF;
  --color-purple-500: #A66DFF;
  --color-purple-400: #C39DFF;
  --color-purple-300: #D2B6FF;
  --color-purple-200: #E1CEFF;
  --color-purple-100: #F0E7FF;
  --color-purple-50: rgb(240 231 255 / 50%);
  --color-red-900: #880727;
  --color-red-800: #AF0932;
  --color-red-700: #D50B3E;
  --color-red-600: #F3164E;
  --color-red-500: #F53D6B;
  --color-red-400: #F76489;
  --color-red-300: #F98BA6;
  --color-red-200: #FBB1C4;
  --color-red-100: #FDD8E1;
  --color-red-25: rgb(253 216 225 / 25%);
  --color-yellow-900: #FAAF00;
  --color-yellow-800: #FFC234;
  --color-yellow-700: #FFCE5C;
  --color-yellow-600: #FFDA85;
  --color-yellow-500: #FFE7AD;
  --color-yellow-400: #FFF3D6;
  --color-yellow-300: #FFFCF2;
  --color-yellow-200: #FFF6E2;
  --color-green-900: #17663A;
  --color-green-800: #1E874C;
  --color-green-750: #008616;
  --color-green-700: #26A95F;
  --color-green-600: #2DCA72;
  --color-green-500: #56D990;
  --color-green-400: #84E4AE;
  --color-green-300: #B2EECC;
  --color-green-200: #DFF8EA;
  --color-green-100: #EEFBF4;
  --color-green-007: #EDF7EF;
  --color-black-1000: #141414;
  --color-black-900: #191919;
  --color-black-850: #222;
  --color-black-800: #303030;
  --color-black-700: #474747;
  --color-black-600: #5E5E5E;
  --color-black-500: #757575;
  --color-black-400: #8C8C8C;
  --color-black-300: #A3A3A3;
  --color-black-200: #BABABA;
  --color-black-150: #D9D9D9;
  --color-black-100: #D1D1D1;
  --color-black-50: #E8E8E8;
  --color-black-30: #F8F8F8;
  --color-black-25: #F4F4F4;
  --color-white-900: #FFF;
  --color-white-500: #CCC;
  --color-label-bg: rgb(209 209 209 / 27%);
  --color-code-gradient-end: #E8DAFF;
  --color-border:  rgb(227 227 227 / 75%);
}