.container {
  position: relative;
}

.text {
  color: var(--text-color-link-active);
  line-height: 25.6px;
}

.button {
  position: absolute;
  top: -3px;
  right: -15px;
  padding-inline: 5px;
  border: none;
  background-color: transparent;
  color: var(--icon-color-red);
  cursor: pointer;
}