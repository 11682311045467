.arrow-button {
  cursor: pointer;
  max-width: 28px;
  max-height: 28px;
}

.page-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: none;
  cursor: pointer;

}

.page-button-active {
  padding: 2px 6px;
  min-width: 21px;
  min-height: 25px;
  border-radius: 3px;
  background: var(--background-primary);
}

.dots-icon {
  display: flex;
  align-items: center;
  cursor: default;
  width: 14px;
}