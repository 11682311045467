.tab-container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 28px;
  margin-bottom: 36px;
  border-bottom: 1px solid var(--color-black-50);
}

.tab-container h2 {
  line-height: 27.32px;
  font-weight: var(--font-weight-600);
  font-size: var(--font-size-p-l);

}

.tab-list {
  display: flex;
  gap: 24px;
  overflow-x: auto;
  list-style-type: none;
  scrollbar-width: none;
}

.tab-item {
  flex-shrink: 0;
  padding-bottom: 8px;
  color: var(--color-black-500);
  line-height: 21.86px;
  font-weight: var(--font-weight-600);
  font-size: var(--font-size-p-m);
  cursor: pointer;
}

.tab-item.active {
  position: relative;
  color: var(--color-black-800);
}

.tab-item.active::after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  border-radius: 18px;
  background: var(--background-primary);
  transition: all .3s ease-in-out;
  content: '';
}