.wrapper{
  display: flex;
  padding: 16px;
  width: 100%;
}

.list {
  margin: 0;
  padding: 16px;
  box-shadow: var(--main-shadow);
  border-radius: 10px;
  list-style: none;
}

.title {
  margin-bottom: 20px;
  color: var(--text-color-primary);
  line-height: 24px;
  font-weight: var(--font-weight-500);
  font-size: var(--font-size-h-xs);
  font-family: var( --font-family);
}

.text {
  color: var(--text-color-light);
  line-height: 20.8px;
  font-weight: var(--font-weight-500);
  font-size: var(--font-size-p-m);
  font-family: var( --font-family);
}

@media (width <= 480px) {
  .text {
    font-weight: var(--font-weight-400);
    font-size: var(--font-size-p-s);

  }

  .title {
    margin-bottom: 5px;
    font-weight: var(--font-weight-500);
    font-size: var(--font-size-p-m);
  }
}

.questions {
  align-self: start;
  width: 100%;
}
