.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.container {
  flex: 1 1 auto;
  margin: 0 auto;
  padding: 0 var(--container-padding);
  width: 100%;
  max-width: var(--container-width);
  min-height: 100vh;
}

@media screen and (width <= 1439px) {
  .container {
    max-width: var(--container-width-lg);
  }
}
  
@media screen and (width <= 992px) {
  .container {
    max-width: var(--container-width-tablet);
  }
}
  
@media screen and (width <= 767px) {
  .container {
    max-width: var(--container-width-sm);
  }
}
  
@media screen and (width <= 576px) {
  .container {
    max-width: var(--container-width-mobile);
  }
}
  