.wrapper {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translateX(-50%);
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding: 16px;
}

.text {
  margin: 0;
  color: var(--text-color-bright);
  text-align: center;
  font-weight: var(--font-weight-500);
  font-size: var(--font-size-h-xs);
}

.loader {
  display: inline-block;
  box-sizing: border-box;
  width: 48px;
  height: 48px;
  border: 5px solid var(--text-color-link-active);
  border-bottom-color: transparent;
  border-radius: 50%;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
} 