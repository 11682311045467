.navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  
}

.register-button {
  color: var(--text-color-white);
  font-weight: var(--font-weight-500);
  font-size: var(--font-size-p-l);
}

.login {
  color: var(--text-color-link-active);
  transition: color 0.3s ease;
}

.login:hover {
  color: var(--color-purple-800)
}

@media screen and (width <= 767px) {
  .register-button {
    padding: 0 0.15rem;
    min-width: 150px;
    height: 2rem;
  }

  .navigation {
    gap: 10px;  
  }
}
