.header {
  position: relative;
}

.popup {
  position: relative;
  top: 8px;
  left: 15px;
  padding: 6px;
  border-radius: 20px;
}

.image-wrapper {
  margin-bottom: 24px;
  max-width: 475px;
  overflow: hidden;
  border-radius: 24px;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.link {
  justify-content: end;
}

@media (width < 480px) {
  .item {
    gap: 16px;
  }

  .header-params {
    gap: 16px;
  }

  .image-wrapper {
    margin-bottom: 16px;
    max-width: 260px;
    border-radius: 12px;
  }
}