.wrapper {
  display: flex;
  flex-direction: column;
  gap: 19px;
  max-width: 740px;
}

.banners-container {
  align-items: flex-start;
}

.title {
  margin: 0;
}

.text {
  margin: 0 auto;
  margin-top: 60px; 
  max-width: 480px; 
  text-align: center;
}

@media (width < 768px) {
  .banners-container {
    flex-direction: column;
  }
}