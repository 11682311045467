.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 12px;
  border-radius: 12px;
  background-color: transparent;
  color: var(--text-color-primary);
  text-decoration: none;
  transition: all 0.3s ease-out;
}

.item:hover {
  background-color: var(--background-app);
}

.admin-active {
  background-color: var(--background-admin-sidebar-item);

  &:hover {
    background-color: var(--background-admin-sidebar-item-active);
  }
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-width: 0;
  background-color: transparent;
  color: var(--text-color-primary);
  text-decoration: none;
  transition:
    all 0.3s ease-out;
  cursor: pointer;
}

.wrap {
  position: relative;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  gap: 8px;
}

.notifications {
  align-self: end;
  padding: 4px 8px;
  border-radius: 8px;
  background-color: var(--background-nav-item);
  color: var(--background-primary);
  font-size: var(--font-size-p-s);
}

.side {
  display: flex;
  align-items: center;
  gap: 8px;
}

.fullwidth .notifications {
  font-size: var(--font-size-p-xs);
}

.title {
  transition: all 0.3s ease-in-out;
  text-overflow: clip;
  white-space: nowrap;
}

.icon {
  width: 24px;
  height: 24px;
  transition: all 0.3s ease-in-out;
}

.fullwidth .title {
  opacity: 0;
  position: absolute;
  left: 32px;
  pointer-events: none;
  transition: all 0.3s ease-in-out;
}



.category {
  display: flex;
  flex-direction: column;
  padding: 10px 12px;
  border-radius: 12px;
  transition:
    all 0.3s ease-out;
}

.category:hover {
  background-color: var(--background-light-hover);
}

.fullwidth.category:hover {
  background-color: transparent;
}

.category.fullwidth {
  padding: 0;
}

.expanded .container {
  background-color: var(--background-light-hover);
}

.category.fullwidth .container {
  padding: 10px 12px;
  height: 100%;
  border-radius: 12px;
}

.category.fullwidth .item {
  padding: 10px 12px;
}

.category-expand-icon {
  justify-self: end;
  transition: all 0.3s ease-in-out;
}

.items {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 0;
  max-height: 0;
  overflow: hidden;
  transition: all 0.3s ease-out;
}

.expanded .items {
  margin-top: 8px;
  max-height: 500px;
}

.expanded .category-expand-icon {
  rotate: 180deg;
}




.expanded {
  background-color: var(--background-light-hover);
}

.fullwidth.expanded {
  background-color: transparent;
}

.fullwidth .nested {
  padding: 10px 0
}

.item.active {
  background-color: var(--background-light-hover);
}

.item.active .container {
  color: var(--background-primary);
}

.category .item.active {
  background-color: var(--background-nav-item);
}

.category .item.active svg {
  color: var(--background-primary);
}