.normal-hight {
  height: auto;
}

.wrapper {
  margin-bottom: 24px;
}

.title {
  margin-bottom: 16px;
  color: var(--text-color-light);
  line-height: 19px;
  font-weight: var(--font-weight-500);
  font-size: var(--font-size-p-m);
}

.param-wrapper {
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
  list-style-type: none;
}

.param-wrapper > li:first-child {
  margin-right: 16px;
}

.chip {
  cursor: pointer;
}

.chip:not(:last-of-type) {
  margin-right: 10px;
}

.keywords-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.keywords-wrapper a {
  color: var(--text-color-link-active);
}

.author {
  margin-top: 15px;
  margin-bottom: -10px;
  font-size: var(--font-size-p-s);
}

.author p {
  justify-content: end;
}


.author a {
  pointer-events: none;
  color: var(--text-color-link-active);
}
