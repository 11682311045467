.text-wrapper {
  width: 100%;
  max-width: 246px;
}

.main-title {
  margin-bottom: 28px;
  line-height: 120%;
  font-weight: var(--font-weight-600);
  font-size: var(--font-size-h-xs);
}

.title {
  color: var(--text-color-primary);
  line-height: 120%;
  font-weight: var(--font-weight-600);
  font-size: var(--font-size-p-l);
}

.description {
  color: var(--text-color-primary);
  line-height: 120%;
  font-weight: var(--font-weight-400);
  font-size: var(--font-size-p-s);
}

.textarea {
  display: block;
  margin-top: 20px;
  width: 100%;
  height: 200px;
  min-height: 80px;
  resize: vertical;
  border-radius: 16px;
}

.skills-input {
  align-items: center;
  height: 48px;
}

.skills-select {
  align-items: flex-start;
  margin-bottom: 100px;
  height: 48px;
}

.input-form {
  width: 100%;
  min-width: 100px;
  max-width: 408px;
}

.select {
  width: 100%;
  max-width: 706px;
}

.select > span {
  display: inline-block;
  margin-bottom: 8px;
  color: var(--text-color-light);
  font-weight: var(--font-weight-400);
  font-size: var(--font-size-p-s);
}