.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.user-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
}

.user-wrapper:hover {
  cursor: pointer;
}

.user-wrapper:hover img {
  border: 1px solid var(--border-image-hover);
  border-radius: 10px;
}

.user-name {
  margin-right: 12px;
  line-height: normal;
}

.dropdown {
  position: absolute;
  top: 46px;
  right: 20px;
  right: 0;
  z-index: 2;
  padding: 6px;
  box-sizing: border-box;
  min-width: 192px;
  border-radius: 12px;
  background-color: var(--background-block);
}

.button-wrapper {
  display: flex;
  align-items: center;
  padding: 6px;
  border-radius: 12px;
}

.button-wrapper:hover {
  background-color: var(--background-light-hover);
  color: var(--border-image-hover);
}

.button {
  cursor: pointer;
  color: var(--text-color-black-landing);
}


.icon {
  margin-right: 15px;
  max-width: 24px;
}