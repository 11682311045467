.preparation-empty {
  padding: 16px 24px;
}

.inactive-description {
  margin-top: 7px;
}

.preparation-noactiveimage {
  margin-top: 12px;
  width: 100%;
  height: 229px;
  border-radius: 12px;
  object-fit: cover;
  object-position: right;
}

@media (width <= 1198px) {
  .preparation-empty {
    padding: 16px;
  }
}

@media (width <= 480px) {
  .preparation-empty {
    padding: 0;
  }
}