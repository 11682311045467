.file-upload-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  padding: 0 40px;
  width: 100%;
  min-height: 188px;
  border: 2px dashed var(--color-black-200);
  border-radius: 16px;
  text-align: center;
  cursor: pointer;
}

.file-upload-container.active {
  background-color: var(--background-progress-bar);
}

.file-upload-container > p {
  pointer-events: none;
  color: var(--color-black-500);
  font-weight: var(--font-weight-400);
  font-size: var(--font-size-s);
}

.file-upload-container > p > span {
  pointer-events: none;
  color: var(--color-purple-700);
  font-weight: var(--font-weight-400);
  font-size: var(--font-size-s);
}

.extension-descriptions {
  pointer-events: none;
  color: var(--color-black-300);
  font-weight: var(--font-weight-400);
  font-size: var(--font-size-xs);
}

.svg-wrapper {
  width: 32px;
  height: 32px;
}

.file-input {
  opacity: 0;
  position: relative;
  z-index: 1;
  width: 0;
  height: 0;
  line-height: 0;
}

@media (width <= 360px) {
  .file-upload-container {
    padding: 8px 24px;
  }
}