.container {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 100vw;
  height: 100vh;
}

.logo-wrapper {
  display: none;
  align-self: start;
  padding: 20px 30px;
}

.logo-wrapper svg {
  display: block;
}

@media (width <= 768px) {
  .container {
    flex-direction: column;
  }
	
  .logo-wrapper {
    display: block;
  }
}

@media (width <= 480px) {
  .logo-wrapper {
    padding: 12px 16px;
  }
}