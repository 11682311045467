.slider {
  display: grid;
  gap: 16px 20px;
  padding-bottom: 24px;
  border-bottom: 1px solid var(--border-color);
}

.slider-with-image {
  grid-template: auto 1fr auto / minmax(50%, auto) minmax(300px, 526px);
  grid-template-areas:
    'question image'
    'wrapper image'
    'buttons image';
}

.slider-without-image {
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    'question'
    'wrapper'
    'buttons';
}

.wrapper {
  grid-area: wrapper;
  padding-bottom: 8px;
}

.question {
  display: list-item;
  grid-area: question;
  margin-left: 22px;
  list-style: disc;
  font-size: var(--font-size-h-xs);
}

.question::marker {
  color: var(--text-color-link-active);
}

.answer {
  margin-right: auto;
  border: none;
  background: none;
  color: var(--text-color-link-active);
  font-size: var(--font-size-p-s);
  cursor: pointer;
}

.response-buttons {
  display: flex;
  grid-area: buttons;
  align-items: start;
  margin-top: auto;
  width: fit-content;
  height: fit-content;
}

.image {
  grid-area: image; 
  aspect-ratio: 526 / 303;
  width: 100%;
  min-width: 300px;
  max-width: 526px; 
  height: auto; 
  border-radius: 16px;
}

.answer-wrapper {
  font-size: var(--font-size-p-m);
}

@media (width <= 1024px) {
  .slider {
    grid-template-columns: auto;
  }

  .image {
    width: 100%;
    min-width: none;
    max-width: none;
  }

  .slider-with-image {
    grid-template-areas:
      'question'
      'image'
      'wrapper'
      'buttons';
  }

  .slider-without-image {
    grid-template-areas:
      'question'
      'wrapper'
      'buttons';
  }
}

@media (width <= 480px) {
  .slider {
    padding-bottom: 16px;
  }

  .response-buttons{
    gap: 16px;
  }

  .question {
    font-weight: var(--font-weight-600);
    font-size: var(--font-size-p-l);
  }

  .answer-wrapper {
    font-size: var(--font-size-p-s);
  }
}
