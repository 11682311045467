.button {
  justify-content: flex-start;
  padding: 6px 10px;
  border-radius: 12px;
}

.button:focus {
  border: 1px solid transparent;
}

.button:not([disabled]):hover {
  color: var(--color-purple-700);
}