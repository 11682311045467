.form {
  margin-top: 300px;
}

.main-title {
  margin-bottom: 28px;
  line-height: 120%;
  font-weight: var(--font-weight-600);
  font-size: var(--font-size-h-xs);
}

.title {
  color: var(--text-color-primary);
  line-height: 120%;
  font-weight: var(--font-weight-600);
  font-size: var(--font-size-p-l);
}

.description {
  color: var(--text-color-primary);
  line-height: 120%;
  font-weight: var(--font-weight-400);
  font-size: var(--font-size-p-s);
}


.selected-questions-title {
  color: var(--text-color-primary);
  line-height: 120%;
  font-weight: var(--font-weight-500);
  font-size: var(--font-size-p-m);
}

.text-wrapper {
  width: 100%;
  max-width: 246px;
}

.button {
  align-self: end;
}

.chip {
  justify-content: space-between;
  width: 100%;
  max-width: 610px;
  color: var(--color-black-1000);
  font-size: var(--font-size-p-s);
}

.label {
  color: var(--text-color-the-lightest);
  font-weight: var(--font-weight-700);
  font-size: var(--font-size-xsmall);
  font-style: normal;
}

.titles {
  width: 135px;
}

.textarea {
  display: block;
  margin-top: 20px;
  width: 100%;
  height: 180px;
  min-height: 80px;
  resize: vertical;
  border-radius: 16px;
}

.paid-label {
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--color-black-600);
  font-size: var(--font-size-p-s);
}

.input-form {
  width: 100%;
  min-width: 100px;
  max-width: 408px;
}


.collection-input {
  align-items: center;
  height: 48px;
}

.collection-select {
  align-items: flex-start;
  margin-bottom: 100px;
  height: 48px;
}


.select {
  width: 360px;
}

.select > span {
  display: inline-block;
  margin-bottom: 8px;
  color: var(--text-color-light);
  font-weight: var(--font-weight-400);
  font-size: var(--font-size-p-s);
}

