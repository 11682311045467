.wrapper {
  position: relative;
  width: 40px;
  height: 40px;
}

.avatar {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.border {
  width: 40px;
  height: 40px;
  border: 1px solid var(--background-primary);
  border-radius: 10px;
}