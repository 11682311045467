.selection {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.select {
  width: 100%;
  max-width: 320px;
}

.select select {
  height: 46px;
}

.select > div > div {
  border-radius: 12px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  max-width: 740px;
}