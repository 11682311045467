.avatar-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6px;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  background-color: var(--color-black-25);
}

.avatar-icon {
  width: 100%;
  max-width: 90px;
  height: 100%;
  max-height: 105px;
}