.title {
  margin: 0;
}

.content {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.container { 
  display: flex; 
  flex-direction:column; 
  gap: 20px 
}

.main-content {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 20px;
  width: 66%;
}

.side-content-tmp {
  width: 360px;
}

.separator {
  margin: 16px 0;
  width: 100%;
  height: 1px;
  background-color: var(--color-black-50);
}

.profile-card-skeleton, 
.card-skeleton {
  gap: 0;
  height: auto;
} 

.profile-card-skeleton {
  padding: 16px;
}

.flex-profile-skeleton {
  display: flex;
  gap: 16px;
}

.card-skeleton {
  padding: 24px;
}

.profile-card-skeleton, 
.card-skeleton:not(:last-child) {
  margin-bottom: 24px;
}

.header-card-skeleton {
  margin-bottom: 20px;
}

.sleleton-link {
  margin-left: auto;
  width: 100px;
  height: 20px;
}

@media (width <= 1280px) {
  .sleleton-link {
    width: 20px;
  }
}

@media (width <= 1440px) {
  .content {
    flex-direction: column-reverse;
  }

  .side-content-tmp {
    flex: 1;
    width: 100%;
  }

  .main-content {
    width: 100%;
  }
}

@media (width <= 720px) {
  .flex-profile-skeleton {
    flex-direction: column;
  }

  .sleleton-link-profile {
    order: -1;
  } 

  .card {
    min-height: 100%;
  }
}