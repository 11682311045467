.container{
  min-width: 242px;
}

.header{
  margin-bottom: 14px;
  padding-bottom: 14px;
  border-bottom: solid 1px #EAEAEA;
 
}

.header-text{
  text-align: center;
}

.full-name{
  margin-bottom: 5px;
}

.avatar {
  width: 58px;
  height: 58px;
  overflow: hidden;
  border: solid 1px var(--color-purple-700);
  border-radius: 10px;
}

.avatar-image {
  width: 100%;
  height: 100%;
}