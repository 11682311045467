.wrapper {
  display: flex;
  gap: 20px;
  width: 100%;
}

.mobile, .tablet {
  flex-direction: column;
}

.main {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 20px;
}

.popover-additional-wrapper {
  width: 100%;
  max-width: 314px;
}

.additional {
  display: flex;           
  flex-direction: column;
  gap: 20px;
  width: 360px;
  height: auto;
}

.additional-info-wrapper {
  padding: 8px;
  box-shadow: none;
}

.additional h4 {
  color: var(--text-color-light);
}


:is(.additional, .mobile) > div {
  height: auto;
}

.author {
  grid-area: author;
  margin: 0 auto;
  width: auto;
}

.author p {
  justify-content: center
}

.author a {
  pointer-events: none;
  color: var(--text-color-link-active);
}

.header {
  display: flex;
  gap: 10px;
}

.title-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  width: 100%;
}

.actions-wrapper {
  margin-bottom: 20px;
}

.actions {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 16px;
}

.mobile .actions {
  justify-content: flex-start;
}

.info {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.info:not(:last-child) {
  margin-bottom: 24px;
}

.params {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.popover-additional {
  position: absolute;
  top: 26px;
  right: 400px;
  display: none;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--border-right-color);
  border-radius: 8px;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
}

.popover-additional:has(button.active) {
  border: 1px solid var(--button-border-secondary-focus);
}

@media (width <= 1023px) {
  .popover-additional {
    display: block;
  }

  .popover-open {
    border: 1px solid var(--text-color-link-active);
  }

  .additional {
    display: flex;
    flex-direction: column;
    justify-content: end;
    gap: 20px;
    width: 360px;
    height: auto;
  }
}
 