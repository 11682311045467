.wrapper {
  position: relative;
  flex-shrink: 0;
  width: 70px;
  height: 50px;
  border-radius: 4px;
  background-color: var(--background-nav-item);
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: fill;
  border-radius: inherit;
}

.svg {
  position: absolute;
  top: 20%;
  left: 0;
  width: 100%;
  height: 60%;
  object-fit: fill;
  fill: var(--color-purple-900);
}

@media (width <=480px) {
  .wrapper {
    width: 268px;
    height: 193px;
  }
}