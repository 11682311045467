.button {
  justify-content: flex-start;
  padding: 6px 10px;
  height: 38px;
  border-radius: 12px;
  color: var(--color-black-1000);
  font-weight: var(--font-weight-500);
  font-size: var(--font-size-p-m);
}

.button:hover {
  color: var(--color-purple-800);
}

.button:focus {
  border: 1px solid transparent;
}

.button:not([disabled]):hover {
  color: var(--color-purple-700);
}