.wrapper {
  display: flex;
  align-items: center;
  gap: 12px;
}

.card-title {
  margin: 0;
  color: var(--text-color-black-landing);
  line-height: 127%;
  font-weight: var(--font-weight-500);
  font-size: var(--font-size-large-34);
}

.card-text {
  margin-top: 12px;
  width: 100%;
  max-width: 476px;
  color: var(--text-color-black-landing);
  line-height: 120%;
  font-weight: var(--font-weight-400);
  font-size: var(--font-size-p-m);
}

.svg {
  width: 32px;
  height: 33px;
  fill: var(--background-primary);
}