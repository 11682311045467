.question-header-wrapper {
  display: flex;
}

.image-wrapper {
  display: flex;
  margin-right: 10px;
  min-width: 170px;
  min-height: 114px;
}

.image-wrapper-mobile {
  display: flex;
  justify-content: center;
  margin-bottom: 16px; /* Отступ перед текстом */
  width: 100%;
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: fill;
  border-radius: inherit;
}

.image-default {
  width: 170px;
  height: 114px;
  border-radius: 12px;
}

.image-mobile {
  width: 296px;
  height: 307px;
  border-radius: 8px;
}

.title-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.title {
  margin-bottom: 8px;
  color: var(--text-color-primary);
  line-height: 28.8px;
  font-weight: var(--font-weight-500);
  font-size: var(--font-size-h-s);
}

.description {
  color: var(--text-color-light);
  line-height: 20.8px;
  font-weight: var(--font-weight-500);
  font-size: var(--font-size-p-m);
}

.description-wrapper {
  display: flex;
}

.label-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  padding: 2px 8px;
  width: auto;
  max-height: 20px;
  border-radius: 30px;
  background-color: var(--color-green-007);
}

.label {
  color: var(--color-green-750);
  font-weight: var(--font-weight-600);
  font-size: var(--font-size-p-xs);
}

@media (width <= 767px) {
  .question-header-wrapper {
    display: flex;
    flex-direction: column;
    align-items: start;
  }
	
  .title-wrapper {
    display: flex;
    flex-direction: row;
    margin-left: 0;
    max-width: 100%;
  }
 
  .label-wrapper {
    display: flex;
  }
}
 
@media (width <=564px) {
  .title-wrapper {
    display: flex;
    margin-left: 0;
  }
 
  .title {
    line-height: 120%;
    font-size: var(--font-size-p-m);
  }
 
  .description {
    line-height: 136%;
    font-size: var(--font-size-p-s);
  }
}