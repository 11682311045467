.preferences {
  display: flex;
  align-items: center;
  gap: 16px;
  border: none;
  background-color: inherit;
  cursor: pointer;
}

.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.avatar {
  width: 40px;
  height: 40px;
}

.auth-btns {
  display: flex;
  align-items: center;
  gap: 26px;
}

.auth-btn {
  cursor: pointer;
}

.settings {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding:6px 6px 14px;
}

.button {
  justify-content: start;
  gap:15px;
  padding: 6px 10px;
  min-width: 192px;
  border-radius: 12px;	
}

.btn-active > .button {
  background-color: var(--palette-ui-black-25);
  color:var(--button-text-color-secondary-active)
}

.btn-active svg {
  fill: var(--button-text-color-secondary-active)
}

@media (width < 1024px) {
  .auth-btns {
    display: none;
  }
  
  .preferences {
    display: none;
  }
}
