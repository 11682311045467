:root {
  --button-background-badge: var(--color-purple-900);

  /* primary */
  --button-text-color-primary: var(--color-white-900);
  --button-background-primary: var(--color-purple-700);
  --button-background-primary-hover: var(--color-purple-800);
  --button-background-primary-active: var(--color-purple-900);
  --button-border-primary-focus: var(--color-purple-900); 
  --button-background-primary-focus: var(--color-purple-800);
  --button-background-primary-disabled: var(--color-black-100);
  
  /* secondary */
  --button-text-color-secondary: var(--color-purple-700);
  --button-background-secondary: var(--color-purple-100);
  --button-background-secondary-hover: var(--color-purple-200);
  --button-text-color-secondary-active: var(--color-purple-800);
  --button-background-secondary-active: var(--color-purple-400);
  --button-background-secondary-focus: var(--color-purple-100);
  --button-border-secondary-focus: var(--color-purple-700);
  --button-background-secondary-disabled: var(--color-black-50);
  --button-text-color-secondary-disabled: var(--color-black-200);
  
  /* outline */
  --button-text-color-outline: var(--color-purple-700);
  --button-border-outline: var(--color-purple-700);
  --button-background-outline-hover: var(--color-purple-50);
  --button-text-color-outline-hover: var(--color-purple-800);
  --button-border-outline-hover: var(--color-purple-700);
  --button-text-color-outline-active: var(--color-purple-800);
  --button-background-outline-active: var(--color-purple-100);
  --button-border-outline-active: var(--color-purple-800);
  --button-border-outline-focus: var(--color-purple-800);
  --button-border-outline-disabled: var(--color-black-100);
  --button-text-color-outline-disabled: var(--color-black-100);
  
  /* tertiary */
  --button-text-color-tertiary: var(--color-black-800);
  --button-background-tertiary-hover: var(--color-black-25);
  --button-background-tertiary-active: var(--color-black-50);
  --button-border-tertiary-focus: var(--color-black-700);
  --button-text-color-tertiary-focus: var(--color-black-700);
  --button-background-tertiary-focus: var(--color-black-25);
  --button-text-color-tertiary-disabled: var(--color-black-200);
  
  /* destructive */
  --button-text-color-destructive: var(--color-white-900);
  --button-background-destructive: var(--color-red-600);
  --button-background-destructive-hover: var(--color-red-700);
  --button-background-destructive-active: var(--color-red-800);
  --button-border-destructive-focus: var(--color-red-800);
  --button-background-destructive-focus: var(--color-red-600);
  --button-background-destructive-disabled: var(--color-red-200);
  
  /* destructive-secondary */
  --button-text-color-destructive-secondary: var(--color-red-600);
  --button-background-destructive-secondary: var(--color-red-100);
  --button-background-destructive-secondary-hover: var(--color-red-200);
  --button-background-destructive-secondary-active: var(--color-red-300);
  --button-text-color-destructive-secondary-active: var(--color-red-700);
  --button-border-destructive-secondary-focus: var(--color-red-600);
  --button-background-destructive-secondary-focus: var(--color-red-100);
  --button-text-color-destructive-secondary-disabled: var(--color-red-200);
  --button-background-destructive-secondary-disabled: var(--color-red-25);

  /* destructive-outline */
  --button-text-color-destructive-outline: var(--color-red-600);
  --button-border-destructive-outline: var(--color-red-600);
  --button-border-destructive-outline-hover: var(--color-red-600);
  --button-background-destructive-outline-hover: var(--color-red-25);
  --button-text-color-destructive-outline-active: var(--color-red-800);
  --button-border-destructive-outline-active: var(--color-red-800);
  --button-border-destructive-outline-focus: var(--color-red-600);
  --button-text-color-destructive-outline-focus: var(--color-red-600);
  --button-text-color-destructive-outline-disabled: var(--color-red-200);
  --button-border-destructive-outline-disabled: var(--color-red-200);

  /* destructive-tartiary */
  --button-text-color-destructive-tartiary: var(--color-red-600);
  --button-background-destructive-tartiary-hover: var(--color-red-25); 
  --button-text-color-destructive-tartiary-active: var(--color-red-700);
  --button-background-destructive-tartiary-active: var(--color-red-100);
  --button-border-destructive-tartiary-focus: var(--color-red-800);
  --button-text-color-destructive-tartiary-disabled: var(--color-red-200);
  
  /* link primary */
  --link-text-color-primary: var(--color-purple-700);
  --link-text-color-primary-hover: var(--color-purple-700);
  --link-text-color-primary-active: var(--color-purple-800);
  --link-text-color-primary-focus: var(--color-purple-800);
  --link-text-color-primary-disabled: var(--color-purple-300);
  
  /* link secondary */
  --link-text-color-secondary: var(--color-black-800);
  --link-text-color-secondary-hover: var(--color-black-900);
  --link-text-color-secondary-active: var(--color-black-900);
  --link-text-color-secondary-focus: var(--color-black-900);
  --link-text-color-secondary-disabled: var(--color-black-200);
  
  /* link destructive */
  --link-text-color-destructive: var(--color-red-600);
  --link-text-color-destructive-hover: var(--color-red-600);
  --link-text-color-destructive-active: var(--color-red-700);
  --link-text-color-destructive-focus: var(--color-red-700);
  --link-text-color-destructive-disabled: var(--color-red-200);

  /* link purple  */
  --link-text-color-purple: var(--color-purple-700);
  --link-text-color-purple-hover: var(--color-purple-900);
  --link-text-color-purple-active: var(--color-purple-900);
  --link-text-color-purple-focus: var(--color-purple-900);
  --link-text-color-purple-disabled: var(--color-purple-200);
}