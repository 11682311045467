.description {
  flex: 1 0 350px;
}
  
.description h3 {
  margin-bottom: 8px;
  color: var(--color-black-800);
  font-weight: var(--font-weight-600);
  font-size: var(--font-size-p-l);
}
  
.description p {
  margin-bottom: 8px;
  color: var(--color-black-800);
  font-weight: var(--font-weight-400);
  font-size: var(--font-size-p-s)
}
  
.textarea-container {
  width: 100%;
  max-width: 706px;
}
  
.textarea {
  padding: 16px;
  width: 100%;
  max-width: 706px;
  height: 150px;
  overflow-y: auto;
  border: 1px solid var(--color-black-50);
  border-radius: 16px;
  resize: none;
}
 
.textarea::-webkit-scrollbar {
  width: 3px; 
}
 
.textarea::-webkit-scrollbar-thumb {
  background-color: rgb(0 0 0 / 0%);
}

.textarea:focus {
  outline: none;
}

@media (width <= 1440px) {
  .description {
    flex-basis: 230px;
  }
}

@media (width <= 768px) {
  .container {
    flex-wrap: wrap;
  }
}