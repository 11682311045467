.param {
  margin-bottom: 32px;
}

@media (width < 1024px) {
  .param {
    gap: 16px;
  }
}

@media (width < 480px) {
  .param {
    margin-bottom: 24px;
  }
}