.item:not(:last-child) {
  padding-bottom: 8px;
  border-bottom: 1px solid var(--border-color);
}

.link {
  display: flex;
  gap: 8px;
  color: var(--text-color-bright);
  text-decoration: none;
}

.link:not(:last-child) {
  padding-bottom: 8px;
  border-bottom: 1px solid var(--border-color);
}

.title {
  position: relative;
  display: flex;
  align-items: baseline;
  margin: 0;
  transition: color 0.3s ease-in-out;
}

.title::before {
  flex-shrink: 0;
  margin-right: 6px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: var(--text-color-link-active);
  font-size: var(--font-size-p-s);
  transform: translateY(-50%);
  content: ''
}

.title-skeleton::before {
  display: none;
}

@media (width <= 768px) {
  .link:not(:last-child) {
    border-bottom: none;
  }
	
  .params {
    gap: 12px;
  }
	
  .item:not(:last-child) {
    border-bottom: none;
  }
}

@media (width <= 480px) {
  .link {
    flex-direction: column;
    justify-content: center;
  }
	
  .image {
    aspect-ratio: 4 / 3;
    width: 100%;
    height: auto;
  }

  .params {
    justify-content: start;
  }
}