@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");
@import url('./normalize.css');
@import url('./variables/pallete.css');
@import url('./variables/button-colors.css');
@import url('./variables/themes.css');
@import url('./variables/main-variables.css');

body {
  min-height: 100vh;
  background-color: var(--background-app);
  line-height: 140%;
  font-weight: var(--font-weight-500);
  font-size: var(--font-size-p-m);
  font-family: var(--font-family);
  user-select: none;
}

input, textarea {
  user-select: text;
}

.container {
  margin-inline: auto;
  width: min(100% - 60px, 1760px);
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: var(--border-color);
  transition: background-color 0.3s ease-in-out;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--color-black-300);
}

::-webkit-scrollbar-thumb:active {
  background: var(--color-purple-400);
}

button,
a {
  -webkit-tap-highlight-color: transparent;
}

div[data-floating-ui-portal] > div > div {
  max-height: 380px;
  overflow-y: scroll;
}